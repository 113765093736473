// src/projectsData.js
const projectsData = [
    {
      id: 1,
      title: 'Home lab',
      description: 'All my home lab hardware projects, servers, and setups.',
      imageUrl: '/images/proxmox_logo.webp',
      detailLink: '/home-lab'
    },
    {
      id: 2,
      title: 'Docker Projects',
      description: 'Self-hosted docker container based projects.',
      imageUrl: '/images/Docker_logo.png',
      detailLink: '/Docker'
    },
    {
      id: 3,
      title: 'Code Creations',
      description: 'If it required some coding, I put it here.',
      imageUrl: '/images/jupyter.webp',
      detailLink: '/CodeP'
    }
    // Add more projects as needed
  ];
  
  export default projectsData;
  