import React from 'react';
import './Footer.css';
import logo from '../logo.svg'; // Adjust the path if your logo.svg is elsewhere

function Footer() {
  return (
    <footer className="footer">
      <p>
        Made with TLC and React
        <img src={logo} alt="React Logo" className="footer-logo" />
      </p>
    </footer>
  );
}

export default Footer;

