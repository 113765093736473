// src/components/Projects.js
import React from 'react';
import ProjectCard from './ProjectCard';
import projectsData from './projectsData';
import './Projects.css';

function Projects() {
  return (
    <div className="projects-section">
      <h2>Projects</h2>
      <div className="projects-grid">
        {projectsData.map((project) => (
          <ProjectCard
            key={project.id}
            title={project.title}
            description={project.description}
            imageUrl={project.imageUrl}
            detailLink={project.detailLink}
          />
        ))}
      </div>
    </div>
  );
}

export default Projects;



