import React, { useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ParticlesBackground = ({ darkMode }) => {
  const location = useLocation();
  const particlesContainer = useRef(null);

  const initParticles = useCallback(() => {
    if (particlesContainer.current) {
      if (window.pJSDom && window.pJSDom.length > 0) {
        window.pJSDom.forEach((dom) => dom.pJS.fn.vendors.destroypJS());
        window.pJSDom = [];
      }

      window.particlesJS(particlesContainer.current.id, {
        particles: {
          number: { value: 50, density: { enable: true, value_area: 800 } },
          color: { value: darkMode ? "#ffffff" : "#000000" },
          shape: { type: "circle", stroke: { width: 0, color: "#000000" }, polygon: { nb_sides: 5 } },
          opacity: { value: 0.5, random: false, anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false } },
          size: { value: 3, random: true, anim: { enable: false, speed: 40, size_min: 0.1, sync: false } },
          line_linked: { enable: true, distance: 150, color: darkMode ? "#ffffff" : "#000000", opacity: 0.4, width: 1 },
          move: { enable: true, speed: 2, direction: "none", random: false, straight: false, out_mode: "bounce", bounce: false, attract: { enable: false, rotateX: 600, rotateY: 1200 } }
        },
        interactivity: {
          detect_on: "canvas",
          events: { onhover: { enable: true, mode: "repulse" }, onclick: { enable: true, mode: "push" }, resize: true },
          modes: { repulse: { distance: 200, duration: 0.4 }, push: { particles_nb: 4 } }
        },
        retina_detect: true
      });
    }
  }, [darkMode]);

  useEffect(() => {
    initParticles();
  }, [darkMode, location.pathname, initParticles]);

  return <div id="particles-js" ref={particlesContainer} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: '-1', pointerEvents: 'none' }} />;
};

export default ParticlesBackground;