import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Footer from './components/Footer';
import Project2Details from './components/Project2Details';
import FloatingToggles from './components/FloatingToggles';
import HomeLab from './components/HomeLab';
import Docker from './components/Docker';
import CodeP from './components/CodeP';
import ParticlesBackground from './components/ParticlesBackground';
import './App.css';

function AppContent() {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : true;
  });
  const location = useLocation();

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  return (
    <div className="App">
      <div className="background-overlay"></div>
      <ParticlesBackground darkMode={darkMode} />
      <FloatingToggles darkMode={darkMode} setDarkMode={setDarkMode} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home-lab" element={<HomeLab />} />
        <Route path="/Docker" element={<Docker />} />
        <Route path="/CodeP" element={<CodeP />} />
        <Route path="/project2-details" element={<Project2Details />} />
      </Routes>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;