import React, { useRef, useEffect } from 'react';
import Typing from 'react-typing-effect';
import { Link as ScrollLink } from 'react-scroll';
import './Home.css';
import ParticlesBackground from './ParticlesBackground';
import Projects from './Projects';
import About from './About';
import Contact from './Contact';
import headshot from '../assets/images/rsz_mike_head_shot.png';

function Home() {
  const homeRef = useRef(null);

  useEffect(() => {
    if (homeRef.current) {
      homeRef.current.scrollTo(0, 0);
    }

    const sections = document.querySelectorAll('.home-section');
    
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, {
      threshold: 0.1
    });

    sections.forEach(section => {
      observer.observe(section);
    });

    return () => {
      sections.forEach(section => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <div ref={homeRef}>
      <ParticlesBackground darkMode={true} />
      <div className="home-wrapper">
        <div className="headshot-container">
          <img src={headshot} alt="Mike Murphy" className="headshot" />
        </div>
        <div id="home" className="home">
          <div className="home-container">
            <Typing
              text={[
                "Welcome to Mike Murphy's digital playground.",
                "Part portfolio, part tech experiment, 100% prone to feature creep."
              ]}
              speed={75}
              eraseSpeed={50}
              eraseDelay={2000}
              className="typing-effect"
            />
            <nav>
              <ul>
                <li><ScrollLink to="about" smooth={true} duration={500}>About</ScrollLink></li>
                <li><ScrollLink to="projects" smooth={true} duration={500}>Projects</ScrollLink></li>
                <li><ScrollLink to="contact" smooth={true} duration={500}>Contact</ScrollLink></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div id="about" className="home-section">
        <About />
      </div>
      <div id="projects" className="home-section">
        <Projects />
      </div>
      <div id="contact" className="home-section">
        <Contact />
      </div>
    </div>
  );
}

export default Home;