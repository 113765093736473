import React, { useEffect } from 'react';
import './HomeLab.css';
import reactLogo from '../logo.svg';  // Adjust the path as necessary
import jupyter from '../assets/images/jupyter.webp';
import emailPython from '../assets/images/email-with-python.png';
import pythonImg from '../assets/images/python.jpg';
import appScripts from '../assets/images/App_scripts.webp';
import Ahk from '../assets/images/Autohotkey_Logo.png';
const CodeP = () => {
  useEffect(() => {
    const cards = document.querySelectorAll('.home-lab-card');
    console.log('Number of cards found:', cards.length);
    
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          console.log('Card intersecting:', entry.target);
          setTimeout(() => {
            entry.target.classList.add('visible');
          }, 100); // 100ms delay
        }
      });
    }, {
      threshold: 0.1,
      rootMargin: '0px 0px -100px 0px' // Start animation slightly before the card comes into view
    });
  
    cards.forEach(card => {
      observer.observe(card);
      console.log('Observing card:', card);
    });
  
    return () => {
      cards.forEach(card => {
        observer.unobserve(card);
      });
    };
  }, []);
  return (
    <div className="home-lab-section">
      <h2>Code Creations</h2>
      <img src={pythonImg} alt="Proxmox Server" className="home-lab-image" />
      <p className="home-lab-summary">
      Here is where I will post some of my projects large and small that I coded or scripted. Not in any particular order just more what I am working on at the moment.

      </p>
      <div className="home-lab-card">
        <img src={emailPython} alt="Proxmox Server" className="home-lab-image" />
        <div className="home-lab-content">
          <h3>Python Marketing Emailer</h3>
          <p>
          At work, we hadn't completed an email campaign in a while, even though they had been successful for our business model in the past. Given some limitations with MailChimp—mostly not wanting to pay for it—I realized I could leverage my recently acquired skills from my Data Science Specialization. So, I set out to gather every email list I could find, which took some serious digging. Using Pandas, I merged these lists, including unsubscribes, into a master list, removing duplicates and past unsubscribes.
          </p>
          <p>
          Finally, with the MIME (Multipurpose Internet Mail Extensions) Python library and some HTML with inline styling, I created personalized newsletters and other email campaigns that could be refreshed relatively easily. Unsubscribes were handled by a separate script that searched the original sending email for unsubscribers and filtered the data frame accordingly.
          </p>
        </div>
      </div>
      <div className="home-lab-card">
        <img src={appScripts} alt="Jupyter" className="home-lab-image" />
        <div className="home-lab-content">
        <h3>Revolutionizing Client Management with Google Sheets & App Scripts</h3>
        <p>
           In our quest for the perfect software to manage client accounts, invoice clients, and input data swiftly, we hit a snag: nothing available quite fit our unique business needs. So, I decided to take matters into my own hands and build a custom solution using Google Sheets and Google App Scripts.
        </p>
        <p>
         This DIY approach turned out to be a game-changer. With just a few clicks, I created a system that effortlessly generates invoices and drafts emails directly to my inbox, ready to be sent. No more tedious data entry; everything streamlined and efficient.
        </p>
        <p>
         Our old system was a time-consuming beast, but I managed to integrate our existing spreadsheet data into the new setup. Now, with a single click, all the necessary information is inputted into client accounts seamlessly.
        </p>
        <p>
         The real magic lies in the insights this system provides. At a glance, we can see the revenue collected from both cash and insurance for any client, along with the expenses associated with them. This newfound clarity has empowered us to make smarter, faster business decisions.
        </p>
        <p>
         In the end, the time and effort invested in building this system have paid off tenfold. It's not just a tool; it's a timesaver and a decision-making ally, customized perfectly for our needs.
        </p>

          <a href="https://github.com/Micmurf36/Google-App-Scripts-and-Sheets---Client-Accounts" target="_blank" rel="noopener noreferrer">Repo: github.com/Micmurf36/Google-App-Scripts-and-Sheets---Client-Accounts</a>
        </div>
      </div>
      <div className="home-lab-card">
      <img src={jupyter} alt="Jupyter" className="home-lab-image" />
        <div className="home-lab-content">
      <h3>Forex Market Prediction ML Project</h3>
      <p>
        I've been working on an exciting machine learning project aimed at predicting forex market movements. This project combines my interest in finance with my passion for data science and machine learning. Here's an overview of the project:
      </p>
     <ul>
      <li>Data Collection: We've set up an automated system using Python scripts to gather real-time and historical forex data from various sources.</li>
      <li>Data Processing: Using pandas and numpy, we clean and preprocess the data, handling missing values and creating relevant features.</li>
      <li>Model Development: We're experimenting with various ML models, including LSTM networks and ensemble methods, to capture complex patterns in the forex market.</li>
      <li>Backtesting: We've implemented a robust backtesting framework to evaluate our model's performance on historical data.</li>
      <li>Continuous Learning: The system is designed to continuously update and retrain the model as new data becomes available.</li>
    </ul>
    <p>
      While we're still in the experimental stages, this project has been an incredible learning experience in applying machine learning to real-world financial data. It's challenging due to the inherent unpredictability of financial markets, but that's what makes it exciting!
    </p>
    <a href="https://github.com/yourusername/forex-prediction-project" target="_blank" rel="noopener noreferrer">View project on GitHub</a>
  </div>
</div>
<div className="home-lab-card">
  <img src={Ahk} alt="Automation Scripts" className="home-lab-image" />
  <div className="home-lab-content">
    <h3>Automation Scripts</h3>
    <p>
      Both at work and at home, I'm a big fan of using scripts to automate repetitive tasks and streamline my workflow. Here's a brief overview of some of my automation practices:
    </p>
    <ul>
      <li>
        <strong>AutoHotkey at Work:</strong> I use AutoHotkey scripts to automate common phrases in emails and other repetitive text inputs. This saves me time and ensures consistency in my communications.
      </li>
      <li>
        <strong>Bash Scripts at Home:</strong> I've created several bash scripts to simplify my development workflow:
        <ul>
          <li>Quick navigation to project directories</li>
          <li>Automatic activation of virtual environments</li>
          <li>Starting development servers with predefined configurations</li>
        </ul>
      </li>
      <li>
        <strong>Custom Aliases:</strong> I've set up numerous aliases in my .bashrc or .zshrc files to shortcut common commands and operations.
      </li>
    </ul>
    <p>
      These automation efforts might seem small individually, but they add up to significant time savings and reduced cognitive load throughout my day. Plus, creating these scripts is a fun way to continue honing my scripting skills!
    </p>
  </div>
</div>
<div className="home-lab-card">
  <img src={reactLogo} alt="React Logo" className="home-lab-image" />
  <div className="home-lab-content">
    <h3>This Tech-Based Profile Site</h3>
    <p>
      This very website you're browsing is one of my projects! Built using React, it showcases my passion for web development and my various tech endeavors. Here are some key features:
    </p>
    <ul>
      <li>React-based single-page application for smooth, fast user experience</li>
      <li>Custom CSS for responsive design, ensuring great looks on all devices</li>
      <li>Interactive elements like the dark mode toggle and floating navigation</li>
      <li>Integration with particle.js for the dynamic background</li>
      <li>Hosted on my own VPS, likely in a Docker container with Nginx</li>
    </ul>
    <p>
      Building this site has been a great opportunity to apply my React skills and create a living portfolio of my projects. It's constantly evolving as I add new features and refine the design.
    </p>
    <a href="https://github.com/Micmurf36/react_repo_test" target="_blank" rel="noopener noreferrer">View source on GitHub</a>
  </div>
</div>
    </div>
  )
}
export default CodeP;