import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import { useLocation, useNavigate } from 'react-router-dom';
import './FloatingToggles.css';

function FloatingToggles({ darkMode, setDarkMode }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const toggleRef = useRef(null);

  const handleNavigation = (path, section) => {
    setMenuOpen(false);
    if (location.pathname === path) {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      const startY = window.scrollY;
      const startTime = performance.now();
      const duration = 500; // ms

      const animateScroll = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easeProgress = 1 - Math.pow(1 - progress, 3); // Cubic ease-out

        window.scrollTo(0, startY * (1 - easeProgress));

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        } else {
          navigate(path);
          // Remove any scroll restoration
          if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
          }
          // Ensure we're at the top of the new page
          window.scrollTo(0, 0);
        }
      };

      requestAnimationFrame(animateScroll);
    }
  };

  useEffect(() => {
    if (menuOpen && menuRef.current && toggleRef.current) {
      const menuHeight = menuRef.current.offsetHeight;
      toggleRef.current.style.transform = `translateY(${menuHeight + 15}px)`;
    } else if (toggleRef.current) {
      toggleRef.current.style.transform = 'translateY(0)';
    }
  }, [menuOpen]);

  return (
    <div className="floating-toggles">
      <IconButton color="inherit" onClick={() => setMenuOpen(!menuOpen)} className="menu-button">
        <MenuIcon />
      </IconButton>
      {menuOpen && (
        <Box className="menu-popup" ref={menuRef}>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigation('/', 'home')}>
                <ListItemText primary="Home" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigation('/', 'about')}>
                <ListItemText primary="About" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigation('/', 'projects')}>
                <ListItemText primary="Projects" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigation('/', 'contact')}>
                <ListItemText primary="Contact" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigation('/home-lab', '')}>
                <ListItemText primary="Home Lab" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigation('/Docker', '')}>
                <ListItemText primary="Docker" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleNavigation('/CodeP', '')}>
                <ListItemText primary="Code Projects" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      )}
      <IconButton 
        color="inherit" 
        onClick={() => setDarkMode(!darkMode)} 
        className="icon-button"
        ref={toggleRef}
      >
        {darkMode ? <Brightness5Icon /> : <Brightness2Icon />}
      </IconButton>
    </div>
  );
}

export default FloatingToggles;