import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about">
      <h2>About Me</h2>
      <p>Hello! I'm Michael Murphy, a tech enthusiast based in Prescott Valley, AZ. My passion for technology isn't just a career—it's a way of life that extends from my professional work to my personal projects.</p>
  
      <p>By day, I leverage my expertise in IT operations, financial management, and automation to streamline workflows and boost efficiency. I thrive on developing innovative solutions using Python, Google App Scripts, and various other tools. This blend of technical know-how and business acumen allows me to tackle complex challenges and drive meaningful improvements in the workplace.</p>
  
      <p>When I'm not at work, you'll find me immersed in my home lab, experimenting with Linux systems, diving into machine learning projects with Python, or setting up Docker containers for applications like Nextcloud and LLaMA. I love making these services accessible through Cloudflare tunneling, bridging the gap between hobby projects and practical, real-world applications.</p>
  
      <p>But technology isn't my only passion. I'm a proud family man, blessed with an amazing wife, Morgan, and two wonderful children, Mackenzie and Mason. They inspire me daily and remind me of the importance of balancing innovation with the things that truly matter in life.</p>
  
      <p>Throughout my career, I've had the opportunity to make significant impacts, from implementing comprehensive employee benefits packages to developing custom solutions that enhance both productivity and job satisfaction. These experiences have taught me the value of using technology not just for its own sake, but as a means to improve people's lives.</p>
  
      <p>I invite you to explore my site and discover some of the exciting projects I've been working on. Whether you're a fellow tech enthusiast, a potential collaborator, or just curious about the intersection of technology and everyday life, I hope you'll find something here that inspires you!</p>
      <a href="/MichaelMurphyResume_6.6.24.pdf" target="_blank" rel="noopener noreferrer">
          Download My Resume
        </a>
    </div>
  );
}

export default About;
