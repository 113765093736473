import React, { useEffect } from 'react';  // Adjust the path as necessary
import './HomeLab.css';
import proxmoxServer from '../assets/images/Proxmox_server.jpg';
import gamePc from '../assets/images/Gaming_rig.jpg';
import gmktecImg from '../assets/images/GMKtec.jpg';
import rockyLinux from '../assets/images/Rocky_Linux_crop.jpg';
import Rpi from '../assets/images/Rpi.jpg'
import Hack_toys from '../assets/images/Hacking_toys.jpg';

const HomeLab = () => {
  useEffect(() => {
    const cards = document.querySelectorAll('.home-lab-card');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Add a delay before adding the 'visible' class
          setTimeout(() => {
            entry.target.classList.add('visible');
          }, 50); // 300ms delay, adjust as needed
        }
      });
    }, {
      threshold: 0.05 // Increased from 0.1 to 0.25
    });
  
    cards.forEach(card => {
      observer.observe(card);
    });
  
    return () => {
      cards.forEach(card => {
        observer.unobserve(card);
      });
    };
  }, []);
  return (
    <div className="home-lab-section">
      <h2>My Home Lab</h2>
      <img src={gamePc} alt="Proxmox Server" className="home-lab-image" />
      <p className="home-lab-summary">
      Welcome to my home lab, where I dive into a variety of tech projects and experiments. This lab is a reflection of my passion for exploring hardware and optimizing systems. From powerful servers and versatile Raspberry Pi setups to sleek NUC boxes and high-performance gaming rigs, my home lab is equipped to handle a multitude of tasks. Below, you'll find detailed information about each component of my setup and how they contribute to my ongoing tech adventures.


      </p>
      <div className="home-lab-card">
        <img src={proxmoxServer} alt="Proxmox Server" className="home-lab-image" />
        <div className="home-lab-content">
          <h3>Proxmox Server</h3>
          <p>
            My Proxmox server is a trusty workhorse, assembled from parts that get a facelift whenever I upgrade my gaming PC. Currently, it's rocking a Skylake 5600 processor, an Nvidia GTX 1060 GPU, 16GB of DDR4 3200 memory, and a motley crew of two SSDs and 6 or 7 hard drives. Thanks to a PCIe SATA expansion card, it's got more ports than a '90s hacker movie. This power hog can be remotely powered on or off from anywhere in the world via a custom login page hosted on my Raspberry Pi. This is available via Cloudflare tunneling. Link below. She may be a bit old, but she's still got plenty of tricks up her sleeve and a lot of life left in her circuits.
          </p>
          <ul>
            <li>Virtualization: VM's include:</li>
            <ul>
              <li>Ubuntu Server: Running many Docker containers. Currently include - Jupyter Notebook, MySQL, Alpine (For running Python scripts with cron), Nextcloud, Jellyfin, and Cloudflare (Tunneling)</li>
              <li>Linux: Kali - Primary for hacky things, Manjaro, Fedora LXC running Wazuh, Ubuntu LXC</li>
              <li>Windows: Windows server 19, Windows 10: Active directory learning and testing. These incur occasional attacks from Kali.</li>
            </ul>
          </ul>
          <a href="https://prox.murphyhouse.de" target="_blank" rel="noopener noreferrer">prox.murphyhouse.de</a>
        </div>
      </div>
      <div className="home-lab-card">
        <img src={gmktecImg} alt="GMKtec NucBox" className="home-lab-image" />
        <div className="home-lab-content">
          <h3>GMKtec NucBox</h3>
          <p>
            The newest addition as I wanted something more efficient to run services I wanted to be available all the time. This mini-pc with an AMD Ryzen 7 7735HS and iGPU of a Radeon 680M serves this purpose. This is a laptop CPU but no slouch by any means. Combined with 32gb of LPDDR5 and 2tb of NVME storage this handles almost anything you throw at it. My only wish is I had waited to purchase one with an NPU as I have been running local LLM models.
          </p>
          <ul>
            <li>Docker Containers: Jupyter Notebooks, Webui</li>
            <li>LLM's: Ollama through Webui. On public internet. LLama3-8b, Dolphin LLama3-8b, Llama2 uncensored-7b</li>
            <li>Development - Conda environments for development - Web (React for this website), Python, Ai projects.</li>
          </ul>
          <a href="https://webui.murphyhouse.de" target="_blank" rel="noopener noreferrer">webui.murphyhouse.de</a>
        </div>
      </div>
      <div className="home-lab-card">
        <img src={rockyLinux} alt="VPS on Kamatera" className="home-lab-image" />
        <div className="home-lab-content">
          <h3>VPS on Kamatera</h3>
          <p>This Rocky Linux VPS serves many services...</p>
          <ul>
            <li>Services running on this include: Website, Docker Mailserver, Alpine linux for running Python scripts to pull data (API's, Webscrapers, etc), MySQL Database those Python scripts write to, also Wireguard VPN.</li>
            <li>Small VPS - 2 core, 3gb ram, 20gb</li>
            <li>24/7 availability, static ip</li>
          </ul>
          <a href="https://www.kamatera.com" target="_blank" rel="noopener noreferrer">Learn more about Kamatera</a>
        </div>
      </div>
      <div className="home-lab-card">
        <img src={gamePc} alt="Black Mamba - Gaming/ Development pc" className="home-lab-image" />
        <div className="home-lab-content">
          <h3>Black Mamba - Gaming/ Development pc</h3>
          <p>
            Black Mamba is my go-to PC when I need the highest compute power. As an enthusiast who loves building PCs, this machine has seen numerous iterations over the years. Its current configuration is a bit unconventional: an AMD Ryzen 5800x CPU paired with an RTX 3080 GPU. The water loop only cools the CPU, as I never got around to purchasing a water block for the GPU. Aesthetically, it's quite a sight—caught somewhere between an RGB explosion and a cabling nightmare. I went through an RGB phase but never managed to synchronize the lighting, resulting in a vibrant yet chaotic display. Additionally, my constant tinkering has left the cable management in need of some serious attention.
          </p>
          <ul>
            <li>Dual boots Windows 10 and Ubuntu</li>
            <li>High-performance components</li>
            <li>Custom water cooling</li>
          </ul>
          <a href="https://www.example.com" target="_blank" rel="noopener noreferrer">Learn more about my Main PC</a>
        </div>
      </div>
      <div className="home-lab-card">
        <img src={Rpi} alt="Raspberry Pi" className="home-lab-image" />
        <div className="home-lab-content">
          <h3>Raspberry Pi</h3>
          <p>
            The Raspberry Pi serves a login page with a Python Flask app to turn on my ProxMox server. This also serves Adguard in a Docker Container to take down ads on the local network. Finally a container for Cloudflare tunneling for public internet access to any services running on the local network.
          </p>
          <ul>
            <li>Triggers relay for Proxmox server</li>
            <li>Runs Adguard for ad blocking</li>
            <li>Small, energy-efficient device</li>
          </ul>
          <a href="https://proxon.murphyhouse.de" target="_blank" rel="noopener noreferrer">proxon.murphyhouse.de</a>
        </div>
      </div>
      <div className="home-lab-card">
        <img src={Hack_toys} alt="Alienware R7" className="home-lab-image" />
        <div className="home-lab-content">
        <h3>Hacking Toys</h3>
        <p>
           I enjoy playing with a few hacking toys—ethically, of course. Flipper Zero is great fun, and many have built wonderful things with it. I've also made custom Ducky Scripts for both the Flipper and my USB Rubber Ducky. Then there are my throwbacks. An Alfa wifi adapter is a must-have and would be useless without my old Alienware dual-booting Windows and Kali.
        </p>
        <ul>
          <li>Flipper Zero: My favorite toy. Endless fun, though I haven't tinkered with it much lately.</li>
          <li>USB Rubber Ducky: I love Ducky scripts and the creative possibilities they offer. IamJacoby is one of my favorite Ducky/Flipper Bad USB script artists.</li>
          <li>Hak5 Pineapple Nano: Rogue AP fun at its finest.</li>
          <li>ALFA AWUS036NHA Wireless B/G/N USB Adapter: The classic Linux-compatible adapter, still great for network traffic sniffing.</li>
           <li>Alienware R7: Old but gold, dual-booting Windows and Kali. I still use it regularly.</li>
        </ul>

        </div>
      </div>
    </div>
  );
}


export default HomeLab;
