import React, { useEffect } from 'react';
import './HomeLab.css';
import dockerImg from '../assets/images/Docker_logo.png';
import webUi from '../assets/images/WebUI_Murphy.png';
import jupyter from '../assets/images/jupyter.webp';
import Nextcloud from '../assets/images/nextcloud-logo.png';
import dockerMail from '../assets/images/Docker-mail.png';
import containerDump from '../assets/images/Container-dump.jpg';
import mySql from '../assets/images/mysql.png';
const HomeLab = () => {
  useEffect(() => {
    const cards = document.querySelectorAll('.home-lab-card');
    console.log('Number of cards found:', cards.length);
    
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          console.log('Card intersecting:', entry.target);
          setTimeout(() => {
            entry.target.classList.add('visible');
          }, 100); // 100ms delay
        }
      });
    }, {
      threshold: 0.1,
      rootMargin: '0px 0px -100px 0px' // Start animation slightly before the card comes into view
    });
  
    cards.forEach(card => {
      observer.observe(card);
      console.log('Observing card:', card);
    });
  
    return () => {
      cards.forEach(card => {
        observer.unobserve(card);
      });
    };
  }, []);
  return (
    <div className="home-lab-section">
      <h2>Self-hosted - Container Stuff</h2>
      <img src={dockerImg} alt="Proxmox Server" className="home-lab-image" />
      <p className="home-lab-summary">
      Once I learned about Docker, I was hooked. It's a game changer for me, and I've been using it to containerize my various projects. Here's a brief overview of my Docker setup:


      </p>
      <div className="home-lab-card">
        <img src={webUi} alt="Proxmox Server" className="home-lab-image" />
        <div className="home-lab-content">
          <h3>WebUI and LLM's</h3>
          <p>
          I've been using Ollama on my NUC box to run smaller open-source LLMs. The Open WebUI interface operates in a Docker container on the NUC, allowing me to manage multiple LLMs simultaneously and access them through a single interface. Additionally, this setup is tunneled via Cloudflare, enabling me to access it from the open web. This is particularly useful if I have a prompt that I prefer not to send to OpenAI or require an uncensored model. With this configuration, I can utilize my home-based LLMs from anywhere.
          </p>
          <a href="https://webui.murphyhouse.de/" target="_blank" rel="noopener noreferrer">webui.murphyhouse.de</a>
        </div>
      </div>
      <div className="home-lab-card">
        <img src={jupyter} alt="Jupyter" className="home-lab-image" />
        <div className="home-lab-content">
          <h3>Jupyter Notebook</h3>
          <p>
            I have three maybe four different containers running Jupyter Noebooks. My NUC Box server is the primary, one on my gaming PC if I have an ML or AI project I need the RTX3080 for, and one on my Proxmox server. I have done many projects and course work in Jupyter. Noteworthy projects in Python include data harvesters (web scrapers, API, writing to MySQL), ML project for forecasting Forex markets, DIY Email Campaign system for work, and everything from my Data Science Specialization with University of Michigan.
          </p>
          <a href="https://jupyter2.murphyhouse.de" target="_blank" rel="noopener noreferrer">jupyter2.murphyhouse.de</a>
        </div>
      </div>
      <div className="home-lab-card">
        <img src={Nextcloud} alt="Nextcloud" className="home-lab-image" />
        <div className="home-lab-content">
          <h3>Nextcloud</h3>
          <p>My own personal cloud running on my Proxmox server. This is in a Docker on a Ubuntu server VM. I like hording my own data on my own local cloud. This is accessible from open internet as well.</p>
          <a href="https://next.murphyhouse.de" target="_blank" rel="noopener noreferrer">next.murphyhouse.de</a>
        </div>
      </div>
      <div className="home-lab-card">
        <img src={dockerMail} alt="dockerMail" className="home-lab-image" />
        <div className="home-lab-content">
          <h3>Docker Mailserver</h3>
          <p>
          On my VPS, I've got a couple of containers running a mail server via Docker Mailserver. Setting it up was trickier than I originally thought. I had to correctly configure everything from MX records, DKIM, and SPF to TLS certificates. And then, of course, there was the task of actually getting the mail server to send and receive emails. Sending and receiving wasn't too problematic, but I practically jumped for joy when I sent an email that Gmail didn't immediately flag as spam with a warning. Honestly, I haven't kept up with maintenace on this but it's still running!
          </p>
          <a href="mike@murphyhouse.de" target="_blank" rel="noopener noreferrer">mike@murphyhouse.de</a>
        </div>
      </div>
      <div className="home-lab-card">
        <img src={mySql} alt="Raspberry Pi" className="home-lab-image" />
        <div className="home-lab-content">
          <h3>Alpine and MySQL Containers</h3>
          <p>
          A friend and I have been working on a project to create a machine learning model for predicting Forex pairs. However, we're trying to do this on a budget, so we’re not paying for expensive APIs. We've experimented with various methods to get the data we need. Initially, we relied on web scrapers, but quickly realized that they break often and gaps in time series data can be quite problematic. So, we've had to get creative.
          </p>
          <p>
          Our current setup involves an Alpine container running Python scripts to harvest the data we need, using Cron jobs timed to our desired granularity—anywhere from 5 minutes to daily intervals. All this data is written to a MySQL database container running on my VPS. We then pull from this database to clean the data and build our models.
          </p>
          <p>
          We obviously haven’t punched our lottery ticket yet, but we've learned a lot from this project.
          </p>
          <a href="https://proxon.murphyhouse.de" target="_blank" rel="noopener noreferrer">proxon.murphyhouse.de</a>
        </div>
      </div>
      <div className="home-lab-card">
        <img src={containerDump} alt="Container Dump" className="home-lab-image" />
        <div className="home-lab-content">
        <h3>Container Dump</h3>
<p>
  This list could go on forever, so I'm dumping some of the less interesting or less used containers here.
</p>
<ul>
  <li>
    Cloudflare Container for tunneling. I need to give credit where it's due—I have one of these running on almost everything, even if it's unnecessary.
  </li>
  <li>
    Nginx - I have a few of these running all the time, and this website is probably running on my VPS in one right now.
  </li>
  <li>
    Jellyfin - I have a container with this but haven't used it much.
  </li>
  <li>
    Wireguard VPN - Useful for many things like accessing my home network from anywhere and making my IP address the same as my VPS. I run a couple of these.
  </li>
  <li>
    Adguard - In a container on my Raspberry Pi Adguard is running to take down ads. Also DNS web filter for kids.
  </li>
  <li>
    There are a number I am forgetting.
  </li>
</ul>
        </div>
      </div>
    </div>
  );
}

export default HomeLab;