// src/components/ProjectCard.js
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './ProjectCard.css';

function ProjectCard({ title, description, imageUrl, detailLink }) {
  return (
    <Link to={detailLink} className="project-card">
      <div className="project-card-content">
        <div className="project-card-image-container">
          <img src={imageUrl} alt={title} className="project-card-image" />
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </Link>
  );
}

ProjectCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  detailLink: PropTypes.string.isRequired,
};

export default ProjectCard;




