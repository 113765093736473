// src/components/Project2Details.js
import React from 'react';

function Project2Details() {
  return (
    <div className="project-details">
      <h2>Project 2 Details</h2>
      <p>Detailed description and how-to guide for project 2...</p>
    </div>
  );
}

export default Project2Details;

